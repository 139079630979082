import React, { useEffect } from 'react';
import { AUTH } from '../../contexts/FirebaseContext';
import Button from '@mui/material/Button';
import {
  getAuth,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  reauthenticateWithPopup,
} from 'firebase/auth';
import FormDialog from './Modal';
import { LoadingButton } from '@mui/lab';
import * as Sentry from '@sentry/react';

export default function LinkAccount() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [accountLinked, setAccountLinked] = React.useState(false);
  const user = AUTH.currentUser;

  useEffect(() => {
    const checkAccountMethods = async () => {
      Sentry.captureMessage(`Checking account methods: user=${JSON.stringify(user)}`);
      if (!user?.email) return;
      const result = await fetchSignInMethodsForEmail(AUTH, user.email);
      Sentry.captureMessage(`result: user=${JSON.stringify(result)}`);

      if (result.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
        setAccountLinked(true);
      }
      setIsLoading(false);
    };
    checkAccountMethods();
    return () => {};
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitted = (password: string) => {
    onClick(password);
  };

  const onClick = (password: string) => {
    console.log(user);

    if (!user?.email) throw new Error('No user email found');
    fetchSignInMethodsForEmail(AUTH, user.email)
      .then((signInMethods) => {
        console.log({ signInMethods });
        Sentry.captureMessage(`fetchSignInMethodsForEmail: user=${JSON.stringify(signInMethods)}`);
        if (!user?.email) {
          throw new Error(`No user email found for ${JSON.stringify(signInMethods)}`);
        }

        if (signInMethods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
          console.error('This email is already associated with an email/password account.');
          // Handle this scenario, perhaps by notifying the user or prompting to merge accounts
        } else if (signInMethods.includes('google.com')) {
          console.log('This email is associated with Google Sign-In.');
          // Prompt the user to confirm they want to link this account to their current Google account
          const provider = new GoogleAuthProvider();
          return reauthenticateWithPopup(user, provider);
        } else {
          Sentry.captureMessage(`trying to connect `);
          const credential = EmailAuthProvider.credential(user.email, password);
          linkWithCredential(user, credential)
            .then((usercred) => {
              Sentry.captureMessage(`linkWithCredential: user=${JSON.stringify(signInMethods)}`);
              const user = usercred.user;
              alert('Account linking success');
            })
            .catch((error) => {
              Sentry.captureMessage(`FAILED: user=${JSON.stringify(error)}`);
              console.error('Account linking error', error);
            });
        }
      })
      .then(() => {
        if (!user?.email) {
          throw new Error(`No user email found`);
        }
        Sentry.captureMessage(`trying again: `);

        // If no conflict, link the email/password provider
        const credential = EmailAuthProvider.credential(user.email, password);
        linkWithCredential(user, credential)
          .then(() => {
            setAccountLinked(true);
            Sentry.captureMessage(`success: ${JSON.stringify(user)}`);
            alert('Account linking success');
          })
          .catch((error) => {
            Sentry.captureMessage(`failed: ${JSON.stringify(error)}`);
            console.error('Account linking error', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching sign-in methods:', error);
      });
  };
  if (
    user?.uid !== 'sbgxN1wBZ6fm91VZ0l2WwNxRDQE2' &&
    user?.uid !== 'evfYvSq29JNecLz8LNYEdZCJWeA2'
  ) {
    return null;
  }
  return (
    <>
      <LoadingButton
        loading={isLoading}
        disabled={accountLinked}
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary"
      >
        {accountLinked ? 'Account Linked' : 'Link Account'}
      </LoadingButton>
      <FormDialog
        email={user.email as string}
        open={open}
        handleClose={handleClose}
        onSubmitted={onSubmitted}
      />
    </>
  );
}

export enum Subscription {
  PREMIUM = 'premium',
  FREE = 'free',
}

export enum Region {
  EU_WEST_3 = 'europe-west3',
}

export enum Collection {
  PUBLIC = 'public',
  USERS = 'users',
  STRIPE_PRODUCTS = 'products',
  AUTOMATIONS = 'automations',
  API_INTEGRATIONS_READ = 'api_integrations_read',
  API_INTEGRATIONS = 'api_integrations',
  WORKSPACE_REVIEWS = 'workspaceReviews',
  WORKSPACE_FLOW = 'workspaceFlow',
  WORKSPACE_LEADS = 'workspaceLeads',
  WORKSPACE_LINKS = 'workspaceLinks',
  WORKSPACE_BOOKINGS = 'workspaceBookings',
  WORKSPACE_BOOKINGS_SCHEDULES = 'schedules',
  ONBOARDING = 'onboarding',
  USERS_NOTIFICATIONS = 'notifications',
  USERS_REVIEWS = 'reviews',
  USERS_REVIEW_COMMENTS = 'reviews_comments',
  USERS_REVIEW_TRANSCRIPT = 'reviews_transcripts',
  USERS_REVIEW_RENDERS = 'reviews_renders',
  USERS_INVITES = 'invites',
  REVIEW_INVITES_RESEND_REMINDERS = 'invites_resend_reminder',
  USERS_INVITES_REMINDERS = 'invite_reminders',
  USERS_INTEGRATIONS = 'integrations',
  WORKSPACE_INTEGRATIONS = 'integrationsFlow',
  TEAM_INVITES = 'teamInvites',
  USERS_REVIEW_STATS = 'stats',
  PRODUCTS = 'products',
  CUSTOMERS = 'customers',
  PRODUCTS_PRICE = 'price',
  CUSTOMERS_CHECKOUTSESSION = 'checkout_sessions',
  CUSTOMERS_SUBSCRIPTIONS = 'subscriptions',
  USERS_INVITE_ACTIVITY = 'activity',
  // LEADS
  LEADS_FORMS = 'leads_forms',
  LEADS_FORMS_SUGGESTIONS = 'leads_forms_suggestions',
  LEADS_FORMS_SUBMISSIONS = 'leads_forms_submissions',
  // Content
  CONTENT = 'TEST_CONTENT',
  // bookings
  BOOKINGS = 'workspace_bookings',
}

export enum ROOTS {
  PROD = 'https://app.simplyreview.com',
  DEV = 'http://localhost:8080',
}

export enum RequestState {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export enum ReviewType {
  INTRO = 'INTRO',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  VERIFY = 'VERIFY',
}

export enum BookingStep {
  INTRO = 'INTRO',
  SUCCESS = 'SUCCESS',
}

export enum WidgetType {
  MICRO_STARS = 'MICRO_STARS',
  HEADER_BAR_STARS = 'HEADER_BAR_STARS',
  REVIEW_CARDS = 'REVIEW_CARDS',
  BEFORE_AFTER_CARDS = 'BEFORE_AFTER_CARDS',
  VIDEO_CARDS = 'VIDEO_CARDS',
  TEXT_VIDEO_CARDS = 'TEXT_VIDEO_CARDS',
  FLOATING_INTRO_VIDEO = 'FLOATING_INTRO_VIDEO',
  LEAD_FORM = 'LEAD_FORM',
}

export enum InviteStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  SENT = 'SENT',
  REVIEWED = 'REVIEWED',
  REMINDER_SENT = 'REMINDER_SENT',
}

export enum INVITE_ACTIVITY_TYPE {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  REVIEW_RECEIVED = 'REVIEW_RECEIVED',
}

export enum IMAGE_METADATA_TYPE {
  REVIEW_SETTINGS = 'REVIEW_SETTINGS',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
  LEAD_PAGE_LOGO = 'LEAD_PAGE_LOGO',
  LINK_PAGE_LOGO = 'LINK_PAGE_LOGO',
  LINK_IMAGE = 'LINK_IMAGE',
  BOOKING_SETTINGS = 'BOOKING_SETTINGS',
  CHAT_IMAGE = 'CHAT_IMAGE',
}

export enum Intl {
  DA = 'da',
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  NO = 'no',
  NN = 'nn',
  FI = 'fi',
  SE = 'se',
  IT = 'it',
  ES = 'es',
  CH = 'ch',
  US = 'us',
  CY = 'cy',
  PT = 'pt',
  ID = 'id',
  FO = 'fo',
  IS = 'is',
  NL = 'nl',
  PL = 'pl',
  AU = 'au',
  BR = 'br',
  AT = 'at',
  BE = 'be',
}
export enum LanguageCode {
  DK = 'DK',
  EN = 'EN',
  NO = 'NO',
}

export enum PriceId {
  // LEADS
  SIMPLY_LEADS_MONTHLY_EARLYBIRD = 'price_1N5PsECgcqfr3d925dUia9Ta',
  SIMPLY_LEADS_MONTHLY = 'price_1MxqTECgcqfr3d92yul2BGNF',
  SIMPLY_LEADS_YEARLY = 'price_1N0MZZCgcqfr3d9265IgELFU',
  // FLOW
  SIMPLY_FLOW_MONTHLY = 'price_1Mc9W5Cgcqfr3d92iktbkq2B',
  // REVIEW
  SIMPLY_REVIEW_YEARLY = 'price_1PtAKmCgcqfr3d92ukoOIglS',
  SIMPLY_REVIEW_MONTHLY = 'price_1MdrNLCgcqfr3d92ipX2UkZ3',
  // TEAM EDITION
  SIMPLY_REVIEW_TEAM_MONTHLY = 'price_1Meet1Cgcqfr3d92FJeLytvI',
  SIMPLY_REVIEW_TEAM_YEARLY = 'price_1Meeu1Cgcqfr3d92gzrEzx1E',
}
export enum ProductId {
  // LEADS
  SIMPLY_LEADS = 'prod_NjJ5MbiAcNrN5D',
  // FLOW
  SIMPLY_FLOW = 'prod_NMtIL0vO7GhGUi',
  // REVIEW
  SIMPLY_REVIEW = 'prod_NOegOOdZknsZZg',
  // BUNDLE
  SIMPLY_BUNDLE = 'prod_Od5zQsOx6T54Vq',
  // SITE
  SIMPLY_SITE = 'prod_OPfGrOGcY1Oqb5',
}

export enum InviteReminderStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}
